import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class ChecklistService {
	static Criticidades = {
		AttentionPoint: "Ponto de Atenção",
		ImmediateStop: "Parada Imediata"
	};
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listChecklists () {
		const response = await this.axios.get("checklist");
		return response.data;
	}

	async listItensChecklist () {
		const response = await this.axios.get("checklist/itens");
		return response.data;
	}

	async getRelatorio (filter) {
		const response = await this.axios.get("checklist/relatorio", { params: filter });
		return response.data;
	}

	listCriticidades () {
		return [
			{ value: "AttentionPoint", nome: ChecklistService.Criticidades.AttentionPoint },
			{ value: "ImmediateStop", nome: ChecklistService.Criticidades.ImmediateStop }
		];
	}

	async geraPlanilhaXlsx (payload) {
		try {
			const resposta = await this.axios.post(
				"checklist/relatorio/xlsx",
				payload,
				{ responseType: "arraybuffer" }
			);
			return resposta;
		} catch (error) {
			let erro = Buffer.from(error.response.data).toString();
			erro = JSON.parse(erro);
			if (!Object.keys(erro).length)
				return { message: "Erro ao realizar exportação!", type: "error"};

			return erro;
		}
	}

	async geraRelatorioPdf (payload) {
		try {
			const resposta = await this.axios.post(
				"checklist/relatorio/pdf",
				payload,
				{ responseType: "arraybuffer" }
			);
			return resposta;
		} catch (error) {
			let erro = Buffer.from(error.response.data).toString();
			erro = JSON.parse(erro);
			if (!Object.keys(erro).length)
				return { message: "Erro ao realizar exportação!", type: "error"};

			return erro;
		}
	}
}
